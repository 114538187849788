.help {
    height: 100%;
    padding: 16px;
    margin-bottom: calc(env(safe-area-inset-bottom, 0px));
    text-align: center;
}

.help .text {
}

.help .button {
    width: 100%;
    box-sizing: border-box;
    padding: 16px;
    line-height: 17px;
    cursor: pointer;
    text-align: center;
    font-family: 'Nunito', sans-serif !important;
    font-size: 1.1em;
    height: 60px !important;
    border-radius: 30px;
    font-weight: 800;
    background: #fff;
    color: #005A66;
    border: 2px solid #005A66;
    margin: 16px 0;
}

.help .footer {
    margin-top: 80px;
    font-size: 0.8em;
}
