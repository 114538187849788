.steps {
    padding: calc(env(safe-area-inset-top, 0px)) 0 16px 0;
    margin-bottom: calc(env(safe-area-inset-bottom, 0px));
}

.steps .mdhui-navigation-bar {
    background: transparent;
}

.steps .mdhui-navigation-bar .logo {
    text-align: center;
    margin-bottom: 20px;
}

.steps .content {
    padding: 20px 16px 16px 16px;
    background: transparent;
}

.steps .recharts-cartesian-axis-tick {
    font-size: 0.75em;
}

.steps .recharts-bar-rectangle {
    fill: var(--color-muted2);
}

.steps .recharts-label-list text {
    font-size: 0.75em;
}