.mood {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    padding: 16px;
}

.mood .mood-date {
    font-size: 0.8em;
    color: var(--color-muted2);
}

.mood .mood-update {
    display: inline;
    text-decoration: underline;
    color: #005A66;
    margin-left: 8px;
}

.mood .mood-score {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: auto 4px;
    vertical-align: middle;
}

.mood .mood-score.selected {
    background: #eec04c;
}

.mood .mood-score img {
    width: 100%;
}