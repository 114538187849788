.metric {
    display: grid;
    grid-template-columns: auto max-content max-content;
    /*grid-column-gap: 8px;*/ /* temporary */
    align-items: start;
    padding: 8px 16px;
    border-bottom: 1px solid var(--color-muted3);
}

.metric:first-child {
    padding-top: 0;
}

.metric:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.metric .metric-icon {
    margin-right: 4px;
}

.metric .metric-label {
    font-weight: bold;
    color: #005a66;
    margin-bottom: 4px;
}

.metric .metric-value {
    font-weight: bold;
    font-size: 1.5em;
}

.metric .metric-date {
    font-size: 0.8em;
    color: var(--color-muted2);
    align-self: start;
    line-height: 20px;
}

.metric .metric-drill {
    display: none; /* temporary */
    font-size: 0.7em;
    color: var(--color-muted2);
    align-self: start;
    line-height: 20px;
}

.metric .metric-fix {
    font-size: 1.1em;
    padding: 0 16px;
    grid-column: 2 / span 2;
    background: #e1fbf4;
    border: 1px solid #005a66;
    height: 60px;
    width: 83px;
    border-radius: 30px;
    color: #005a66;
    font-weight: 700;
    text-align: center;
    word-spacing: 1000px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}