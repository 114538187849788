.dashboard {
    padding: calc(env(safe-area-inset-top, 0px)) 0 16px 0;
    margin-bottom: calc(env(safe-area-inset-bottom, 0px));
}

.dashboard .header {
    padding: 16px;
}

.dashboard .logo {
    text-align: center;
    margin-bottom: 20px;
}

.dashboard .content {
    padding: 12px 16px 16px 16px;
}

.dashboard .cta-group {
    background: #fff;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    margin-bottom: 16px;
}

.dashboard .cta-title {
    font-size: 1.4em;
    font-weight: 700;
    margin-bottom: 12px;
}

.dashboard .cta-text {
    font-size: 1.2em;
    margin: 16px 0;
}

.dashboard .cta-text:first-child {
    margin-top: 0;
}

.dashboard .cta-text:last-child {
    margin-bottom: 0;
}

.dashboard .cta-text-small {
    font-size: 0.9em;
}

.dashboard .cta:last-child .cta-text:last-child {
    margin-bottom: 0;
}

.dashboard .mdhui-button {
    margin-bottom: 16px;
}

.dashboard .cta:last-child .mdhui-button:last-child {
    margin-bottom: 0;
}

.dashboard .check-in .mdhui-button {
    background: #fff;
    color: #005A66;
    border: 2px solid #005A66;
    font-weight: 800;
}

.dashboard .wellinks {
    padding: 16px;
}

.dashboard .wellinks .mdhui-button {
    background: #E1FBF4;
    color: #005A66;
    border: 2px solid #005A66;
    font-weight: 800;
}

.dashboard .get-help .mdhui-button {
    background: #fff;
    color: #005A66;
    border: 2px solid #005A66;
    font-weight: 800;
}

.dashboard .emr {
    padding: 16px;
}

.dashboard .metrics {
    padding: 8px 0;
}

.dashboard .data-sharing {
    overflow: hidden;
}

.dashboard .blue-button {
    padding: 16px;
}

.dashboard .warning {
    color: var(--color-danger);
}

.dashboard .support {
    padding: 16px;
}

.dashboard .support .mdhui-button {
    background: #fff;
    color: #005A66;
    border: 2px solid #005A66;
    font-weight: 800;
}

.dashboard .support .cta-text-small:first-child {
    margin-bottom: 12px;
}

.dashboard .support-info {
    margin-top: 20px;
}

.dashboard .support-info-label {
    text-transform: uppercase;
    font-size: 0.7em;
    color: var(--color-muted3);
}

.dashboard .support-info-value {
    font-size: 0.9em;
}

.dashboard .small-link {
    background: transparent;
    color: #005A66;
    text-decoration: underline;
    border: none;
    font-size: 14px;
}